import React from 'react';
import { NavLink } from 'react-router-dom';
import Logo from '../Centex.png';
import LogoutBtn from './UI/logoutBtn';
import HeaderDropdown from './UI/HeaderDropdown';

function Header({ empInfo }) {
    return (
        <header id='header' className="section fixed w-full z-30 shadow-lg transition-all duration-700 border-b bg-white text-black">
            <div className="py-3 mx-auto">
                <nav className="flex justify-between items-center">
                    <NavLink to="/" className="flex items-center mr-4">
                        <img src={Logo} className="h-5 md:h-7 mr-2" alt="Logo" />
                    </NavLink>
                    <div className="flex items-center gap-3 text-md md:text-lg">
                        {/* <NavLink to={"/map/"} activeClassName="font-bold" className="text-base hover:text-gray-800 transition duration-300 ease-in-out">map</NavLink> */}
                        {/* <NavLink to="/UndergroundParking/" className=" hover:scale-105 transition hover:underline underline-offset-4">Underground</NavLink>
                        <NavLink to="/OutdoorParking/" className="hover:scale-105 transition hover:underline underline-offset-4">Outdoor</NavLink> */}
                        <HeaderDropdown
                            dropdownTitle="Parking"
                            links={[
                                { name: 'Garage', link: '/GarageParking/' },
                                { name: 'Outdoor', link: '/OutdoorParking/' },
                            ]}
                        />
                        {empInfo?.role === "admin" &&
                            <HeaderDropdown
                                dropdownTitle="Admin"
                                links={[
                                    {name: 'Employees', link: '/employees/'},
                                    {name: 'Inventory', link: '/Inventory/'}
                                ]}
                            />
                        }
                        <LogoutBtn />
                    </div>
                </nav>
            </div>
        </header>
    );
}

export default Header;
