import React, { useState } from 'react'
import { FaEdit, FaTrash } from 'react-icons/fa'
import { PiPasswordDuotone } from 'react-icons/pi'
import { DeleteInventoryItem } from '../../FireBase/utils'
import { CiBarcode } from "react-icons/ci";
import { TbCategory } from "react-icons/tb";
import { BiDetail } from "react-icons/bi";

function ItemCard({ item }) {
    const [loading, setLoading] = useState(false);

    return (
        <div key={item.id} className="transition-all my-2 rounded p-5 bg-slate-200 hover:bg-slate-300 flex justify-between items-center shadow-lg">
            <div className='w-full'>
                <h2 className='centexTextColour font-bold sm:text-xl sm:w-64 flex gap-2 mb-2'>
                    {item.itemName}
                </h2>
                <p className='md:text-base flex gap-1' title='Serial Number'><CiBarcode className='self-center text-base centexTextColour'/> {item.itemSerialNumber}</p>
                <p className='md:text-base flex gap-1' title='Category'><TbCategory className='self-center text-base centexTextColour'/> {item.itemCategory}</p>
                <p className='md:text-base flex gap-1'title='Details'><BiDetail className='self-center text-base centexTextColour'/> {item.itemDiscription}</p>
                <p className='md:text-base flex gap-1'>Remaining Quantity: {item.itemQuantity}</p>
            </div>
            {/* Options */}
            <div className='flex gap-3 sm:text-2xl pl-4'>
                <button title='Delete' className='hover:text-rose-800 hover:scale-125 centexTextColour transition-all'
                    onClick={() => {
                        DeleteInventoryItem({ itemID: item.id })
                    }}
                >
                    <FaTrash />
                </button>
            </div>
        </div>
    )
}

export default ItemCard