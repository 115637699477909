import { doc, updateDoc } from 'firebase/firestore';
import React, { useState } from 'react'
import { db } from '../../FireBase/firebase.config';

function EditOutdoorReservation({ employees, onSubmit, spot }) {
    const [selectedEmployee, setSelectedEmployee] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            // Edit Documnet in Firestore collection
            await updateDoc(doc(db, 'OutdoorParkingSpots', spot.ParkingSpotID), {
                employeeId: selectedEmployee,
            })
            // Reset form fields after submission
            setSelectedEmployee('');
            onSubmit();
        } catch (error) {
            console.error('Error adding document: ', error);
        }
    };

    return (
        <form className='flex flex-col gap-2 w-80' onSubmit={handleSubmit}>
            <h2 className='text-lg font-semibold centexTextColour'>Edit Spot {spot.parkingSpotNumber} Reservation</h2>
            <label>Outdoor Parking Spot Number:</label>
            <h2>{spot.parkingSpotNumber}</h2>
            <label htmlFor="employee">Select Employee: </label>
            <select
                className='border border-gray-300 rounded px-1'
                id="employee"
                value={selectedEmployee}
                onChange={(e) => setSelectedEmployee(e.target.value)}
                required
            >
                <option value="">Select an employee</option>
                {employees.sort((a, b) => a.firstName.localeCompare(b.firstName)).map(employee => (
                    <option key={employee.id} value={employee.id}>{employee.firstName} {employee.lastName}</option>
                ))}
            </select>
            <button
                type="submit"
                className='rounded-md py-2 px-4 hover:scale-105 transition centexBackgroundColour text-white'
            >
                Edit Reservation
            </button>
        </form>
    )
}

export default EditOutdoorReservation