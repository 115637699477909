import React, { useState } from 'react'
import { FaEdit, FaTrash } from 'react-icons/fa'
import { TbCalendarPause } from 'react-icons/tb'
import EditUndergroundReservation from './Forms/EditUndergroundReservation'
import Popup from './UI/Popup'
import PauseReservation from './Forms/PauseReservation'
import { deleteReservation, resumeUndergroundReservation } from '../FireBase/utils'

function UndergroundReservationCard({ reservation, empInfo, date, parkingEmployees, keyVal, spot }) {
    const [loading, setLoading] = useState(false);
    const [isEditSpotReservationOpen, setIsEditSpotReservationOpen] = useState(false);
    const [isDeleteReservationPopupOpen, SetIsDeleteReservationPopupOpen] = useState(false);
    const [isPauseReservationPopupOpen, SetIsPauseReservationPopupOpen] = useState(false);
    const [reservationToEdit, setReservationToEdit] = useState({});
    const togglePopup = (setState) => {
        setState(prevState => !prevState);
    };
    return (
        <div key={keyVal} className={`${reservation?.reservationPausedToday === true ? "bg-zinc-200 shadow-inner" : "bg-white shadow"} sm:p-1 rounded overflow-hidden transition-all  text-xs md:text-lg float align-middle group`}>
            <div className='flex justify-center gap-1 text-base'>
                <h3 className='centexTextColour font-bold text-xs md:text-base'>{reservation?.employee}</h3>
            </div>
            {reservation?.deleteEndOfDay ? <p className='centexTextColour font-light pt-2 text-center text-xs md:text-base'>Reserved till end of day</p> : ""}
            <div className='flex flex-wrap gap-1 justify-center pt-3'>
                {/* Reserved days */}
                {reservation?.reservationPausedToday === true
                    ?
                    <div>
                        <p className='centexTextColour font-light group-hover:hidden'>Paused</p>
                        {(empInfo?.role === "admin" || empInfo?.empID === reservation?.empID) &&
                            <button
                                className='hidden group-hover:flex transition-all p-1 rounded centexBackgroundColour hover:bg-blue-950 text-white hover:scale-105 shadow-2xl font-light text-sm'
                                onClick={() => {
                                    setReservationToEdit({
                                        resID: reservation?.reservationID,
                                    })
                                    resumeUndergroundReservation(reservationToEdit.resID)
                                }}
                            >
                                Resume
                            </button>
                        }
                    </div>
                    : reservation?.daysOfWeek?.map((day, index) => (
                        <div key={index} className={`${date.weekday === day ? "shadow text-white centexBackgroundColour" : "bg-gray-100 shadow-inner text-gray-500"} cursor-default rounded p-1 flex items-center justify-center border-opacity-25  border-blue-950`}>
                            <p className='text-xs'>{day.substring(0, 2)}</p>
                        </div>
                    ))}
            </div>
            {/* Employee reservation action buttons. visible to employee and admin.*/}
            {(empInfo?.role === "admin" || empInfo?.empID === reservation?.empID) &&
                <div className='text-gray-600 text-lg align-baseline pt-4 justify-center'>
                    {!reservation?.deleteEndOfDay &&
                        <>
                            <button title='Edit Reservation' className='hover:text-orange-500 hover:scale-125 transition-all p-2'
                                onClick={() => {
                                    togglePopup(setIsEditSpotReservationOpen)
                                    setReservationToEdit({
                                        resID: reservation?.reservationID,
                                        resEmp: reservation?.employee,
                                        resDaysOfWeek: reservation?.daysOfWeek,
                                        spotNumber: spot.parkingSpotNumber,
                                    })
                                }}>
                                <FaEdit />
                            </button>
                            <button title='Pause Reservation' className='hover:text-orange-500 hover:scale-125 transition-all p-2'
                                onClick={() => {
                                    togglePopup(SetIsPauseReservationPopupOpen)
                                    setReservationToEdit({
                                        resID: reservation.reservationID,
                                        resEmp: reservation.employee,
                                        resDaysOfWeek: reservation.daysOfWeek,
                                        spotNumber: spot.parkingSpotNumber,
                                    })
                                }}>
                                <TbCalendarPause />
                            </button>
                        </>
                    }
                    <button title='Delete Reservation' className='hover:text-rose-800 hover:scale-125 transition-all p-2'
                        onClick={() => {
                            togglePopup(SetIsDeleteReservationPopupOpen)
                            setReservationToEdit({
                                resID: reservation.reservationID,
                                resEmp: reservation.employee,
                                spotNumber: spot.parkingSpotNumber,
                            })
                        }}>
                        <FaTrash />
                    </button>
                </div>
            }
            {isEditSpotReservationOpen && (
                <Popup onClose={() => togglePopup(setIsEditSpotReservationOpen)}>
                    <EditUndergroundReservation onSubmit={() => togglePopup(setIsEditSpotReservationOpen)} employees={parkingEmployees} reservation={reservationToEdit} />
                </Popup>
            )}

            {isDeleteReservationPopupOpen &&
                <Popup onClose={() => togglePopup(SetIsDeleteReservationPopupOpen)}>
                    <h2 className='text-xl mt-2 mb-4 font-semibold text-blue-950 w-80'>Delete {reservationToEdit.resEmp}'s Reservation to parking spot #{reservationToEdit.spotNumber}</h2>
                    <button
                        onClick={() => {
                            setLoading(true);
                            deleteReservation({ resID: reservationToEdit.resID })
                                .then(() => {
                                    togglePopup(SetIsDeleteReservationPopupOpen);
                                    setLoading(false);
                                })
                        }}
                        className='centexBackgroundColour hover:bg-rose-800 text-white font-bold rounded p-2 px-5 mt-10'
                        disabled={loading}
                    >
                        {loading ? 'Deleting...' : 'Confirm Delete'}
                    </button>
                </Popup>
            }

            {isPauseReservationPopupOpen && (
                <Popup onClose={() => togglePopup(SetIsPauseReservationPopupOpen)}>
                    <PauseReservation onSubmit={() => togglePopup(SetIsPauseReservationPopupOpen)} reservation={reservationToEdit} />
                </Popup>
            )}
        </div>
    )
}

export default UndergroundReservationCard