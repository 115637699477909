import { doc, updateDoc } from 'firebase/firestore';
import React, { useState } from 'react'
import { db } from '../../FireBase/firebase.config';

function EditUndergroundReservation({ employees, onSubmit, reservation}) {
    const [selectedEmployee, setSelectedEmployee] = useState("");
    const [selectedDaysOfWeek, setSelectedDaysOfWeek] = useState([]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            // Edit Documnet in Firestore collection
            await updateDoc(doc(db, 'Reservations', reservation.resID), {
                daysOfWeek: selectedDaysOfWeek,
            })
            // Reset form fields after submission
            setSelectedEmployee('');
            onSubmit();
        } catch (error) {
            console.error('Error adding document: ', error);
        }
    };
    const handleDayOfWeekChange = (day, isChecked) => {
        if (isChecked) {
            setSelectedDaysOfWeek(prevDays => [...prevDays, day]);
        } else {
            setSelectedDaysOfWeek(prevDays => prevDays.filter(d => d !== day));
        }
    };

    return (
        <form className='flex flex-col gap-2 w-80' onSubmit={handleSubmit}>
            <h2 className='text-lg font-semibold centexTextColour'>Edit {reservation.resEmp}'s Reservation under Spot {reservation.spotNumber}:</h2>
            <h2 className='font-semibold centexTextColour bg-slate-200 border p-1 rounded'>Reserved under {reservation.resEmp} on <br/> <strong className='font-thin text-sm'>{reservation.resDaysOfWeek.join(', ')}</strong></h2>
            {/* <label htmlFor="employee">Change Employee under this reservation</label> */}
            {/* <select
                className='border border-gray-300 rounded px-1'
                id="employee"
                onChange={(e) => setSelectedEmployee(e.target.value)}
                required
            >
                <option value="">Select employee</option>
                {employees.map(employee => (
                    <option key={employee.id} value={employee.id}>{employee.firstName} {employee.lastName}</option>
                ))}
            </select> */}
            <div>
                <label className='font-bold'>Change Days of the Week:</label>
                <div className="flex flex-wrap gap-2 pt-2">
                    {["Monday", "Tuesday", "Wednesday", "Thursday", "Friday"].map(day => (
                        <label key={day} className="flex items-center gap-1">
                            <input
                                type="checkbox"
                                value={day}
                                checked={selectedDaysOfWeek.includes(day)}
                                onChange={(e) => handleDayOfWeekChange(day, e.target.checked)}
                            />
                            {day}
                        </label>
                    ))}
                </div>
            </div>
            <button
                type="submit"
                className='rounded-md py-2 px-4 hover:scale-105 transition centexBackgroundColour text-white'
            >
                Edit Reservation
            </button>
        </form>
    )
}

export default EditUndergroundReservation