import React, { useState } from 'react';
import { IoIosArrowDown } from "react-icons/io";
import { motion, AnimatePresence } from 'framer-motion';
import { NavLink } from 'react-router-dom';

const HeaderDropdown = ({ dropdownTitle, dropdownLink, links }) => {
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);

    const handleToggleDropdown = () => {
        setIsDropdownOpen(!isDropdownOpen);
    };

    const handleMouseEnter = () => {
        setIsDropdownOpen(true);
    };

    const handleMouseLeave = () => {
        setIsDropdownOpen(false);
    };

    return (
        <div className="relative text-left" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
            <div className='flex'>
                <div className='hover:scale-110'>
                    <NavLink
                        to={dropdownLink}
                        className="flex items-center"
                    >
                        {dropdownTitle}
                        <motion.span
                            className="inline-block ml-1 text-2xl"
                            initial={{ rotate: 0 }}
                            animate={{ rotate: isDropdownOpen ? 180 : 0 }}
                        >
                            <IoIosArrowDown />
                        </motion.span>
                    </NavLink>
                </div>
                <AnimatePresence>
                    {isDropdownOpen && (
                        <motion.div
                            className="absolute z-50 -left-12 mt-4 w-40 rounded-b-xl p-1 drop-shadow-xl bg-white "
                            initial={{ opacity: 0, y: -10 }}
                            animate={{ opacity: 1, y: 15 }}
                            exit={{ opacity: 0 }}
                        >
                            {links.map(({ name, link, clickAction }) => (
                                <NavLink
                                    to={link ? link : ""}
                                    className="block px-2 py-2 text-center text-gray-700 hover:centexTextColour font-thin transition-all text-base hover:scale-105 hover:font-semibold"
                                    onClick={() => setIsDropdownOpen(false)}
                                    key={name}
                                >
                                    {name}
                                </NavLink>
                            ))}
                        </motion.div>
                    )}
                </AnimatePresence>
            </div>
        </div>
    );
};

export default HeaderDropdown;
