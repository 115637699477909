import { doc, updateDoc } from 'firebase/firestore';
import React from 'react';
import { useState } from 'react';
import { db } from '../FireBase/firebase.config';
import { reserveUndergroundSpotForDay } from '../FireBase/utils';
import UndergroundParkingSpotsCard from '../components/UndergroundParkingSpotsCard';
import { MdEmail } from 'react-icons/md';
import { RiAdminFill, RiRemoteControlFill } from 'react-icons/ri';
import { FaClipboardUser } from 'react-icons/fa6';

function Home({ empInfo, date, updatedParkingSpots, outDoorSpots, parkingEmployees }) {
    const [firstName, setFirstName] = useState(empInfo?.firstName);
    const [lastName, setLastName] = useState(empInfo?.lastName);
    const [password, setPassword] = useState();
    const [confirmPassword, setConfirmPassword] = useState();
    const [editingProfile, setEditingProfile] = useState(false);

    const employeeReservedSpots = updatedParkingSpots.filter(spot => {
        return spot.reservedUnder.some(res => res.empID === empInfo?.empID);
    });

    const employeeReservedOutdoorSpots = outDoorSpots.filter(spot => {
        return spot.employeeId === empInfo?.empID;
    });

    // Filter updated parking spots available today
    const availableTodaySpots = updatedParkingSpots.filter(spot => {
        return spot.spotAvailableToday;
    });

    // Merge underground and outdoor reservations
    const allReservedSpots = [
        ...employeeReservedSpots.map(spot => ({ ...spot, type: 'Underground' })),
        ...employeeReservedOutdoorSpots.map(spot => ({ ...spot, type: 'Outdoor' }))
    ];

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            // Update the document in the "Employees" collection with the provided ID
            await updateDoc(doc(db, "Employees", empInfo?.empID), {
                firstName: firstName,
                lastName: lastName,
            }).finally(() => setEditingProfile(false));
            console.log("User edited successfully!");
        } catch (error) {
            console.error("Error editing user: ", error);
        }
    };

    return (
        <section className="py-20 section grid sm:grid-cols-2 grid-cols-1 gap-3 text-sm lg:text-base">
            <div className='bg-gray-200 flex col-span-1 sm:col-span-2 p-3 rounded justify-center shadow-md border'>
                <h2 className="text-2xl font-semibold">Welcome <span className="text-blue-800">{empInfo?.firstName}</span></h2>
            </div>

            {/* Left section */}
            <div className="col-span-1">
                {/* Available spots section */}
                <div className="bg-gray-200 rounded-lg shadow-xl p-5">
                    <h3 className="text-xl md:text-2xl mb-4 text-center">Garage Parking Available Today</h3>
                    <p className="font-thin text-center mb-8">{date.weekday}, {date.month} {date.day}, {date.year}</p>
                    {availableTodaySpots.length > 0 ? (
                        <div className="space-y-4">
                            {availableTodaySpots.map((spot) => (
                                <div key={spot.id} className="transition-all bg-slate-300 rounded border border-zinc-300 shadow-md h-full p-3 flex justify-between items-center">
                                    <p className="font-semibold centexTextColour">Spot #{spot.parkingSpotNumber}</p>
                                    {/* Add buttons to reserve spots */}
                                    { // employee reserved spots holds a list of spots reserved under the logged in user.
                                        // if the list is 0 then show the reserve button.
                                        employeeReservedSpots.length === 0 &&
                                        <button
                                            className="blueHoverBtn"
                                            onClick={() =>
                                                reserveUndergroundSpotForDay({
                                                    spotID: spot.id,
                                                    EmpId: empInfo.empID,
                                                    date: date,
                                                })}
                                        >
                                            Reserve for Today
                                        </button>
                                    }
                                </div>
                            ))}
                        </div>
                    ) : (
                        <p className="font-thin text-gray-500 text-center">No available spots today.</p>
                    )}
                </div>
            </div>

            {/* Right section */}
            <div className="col-span-1">
                {/* Profile section */}
                <div className="bg-gray-200 rounded-lg shadow-xl p-5 mb-4n border">
                    <h3 className="text-xl md:text-2xl mb-4 text-center">Profile Information</h3>
                    <div className='p-2 bg-neutral-100 rounded-lg shadow-inner border-neutral-400'>
                        {editingProfile ? (
                            <form className="space-y-4" onSubmit={handleSubmit}>
                                {/* Form fields for editing profile */}
                                <div className="flex flex-col">
                                    <label htmlFor="firstName" className="font-semibold">First Name:</label>
                                    <input
                                        type='text'
                                        id="firstName"
                                        className='border border-gray-300 rounded-md px-3 py-2 focus:outline-none focus:border-blue-500'
                                        value={firstName}
                                        onChange={(e) => setFirstName(e.target.value)}
                                        required
                                    />
                                </div>
                                <div className="flex flex-col">
                                    <label htmlFor="lastName" className="font-semibold">Last Name:</label>
                                    <input
                                        type='text'
                                        id="lastName"
                                        className='border border-gray-300 rounded-md px-3 py-2 focus:outline-none focus:border-blue-500'
                                        value={lastName}
                                        onChange={(e) => setLastName(e.target.value)}
                                        required
                                    />
                                </div>
                                <div className="flex gap-2">
                                    <button type="button" onClick={() => { setEditingProfile(false); setPassword(""); setConfirmPassword(""); }} className="border border-gray-300 text-gray-700 px-4 py-2 rounded-md hover:bg-gray-100">Cancel</button>
                                    <button type="submit"
                                        className="centexBackgroundColour text-white px-4 py-2 rounded-md hover:bg-blue-900"
                                    >
                                        Save Changes
                                    </button>
                                </div>
                            </form>
                        ) : (
                            <div className="flex flex-col items-start space-y-2 font-thin">
                                {/* Display profile information */}
                                <p className='flex gap-1'><FaClipboardUser className='self-center text-xl' />{empInfo?.firstName} {empInfo?.lastName}</p>
                                <p className='flex gap-1'><MdEmail className='self-center text-xl' />{empInfo?.email}</p>
                                {empInfo?.hasUndergroundRemote === true ?
                                    <p className='flex gap-1'>
                                        <RiRemoteControlFill className='self-center text-xl' />{empInfo?.hasUndergroundRemote ? "Provided" : "Not Provided"}
                                    </p>
                                    :
                                    ""
                                }
                                {empInfo?.role === "admin" ?
                                    <p className='flex gap-2'><RiAdminFill className='self-center text-xl' />{empInfo?.role}
                                    </p>
                                    :
                                    ""
                                }
                                <button onClick={() => setEditingProfile(true)} className="blueHoverBtn font-normal">Edit Profile</button>
                            </div>
                        )}
                    </div>
                    <div className="rounded-lg pt-6">
                        <h2 className="text-xl md:text-2xl mb-4 text-center">Your Parking Reservations</h2>
                        {allReservedSpots.length > 0 ? (
                            <div className={allReservedSpots.length > 1 ? `grid grid-cols-1 md:grid-cols-2 gap-2` : ""}>
                                {allReservedSpots.map((spot, index) => (
                                    <div key={index}>
                                        {spot.type === "Underground" ? (
                                            <div className='shadow-md rounded-xl'>
                                                <UndergroundParkingSpotsCard date={date} empInfo={empInfo} parkingEmployees={parkingEmployees} spot={spot} />
                                            </div>
                                        ) : (
                                            <div className="bg-slate-300 rounded p-1 shadow-md h-full flex flex-col justify-center items-center font-semibold centexTextColour">
                                                <p className=''>{spot.type} Parking Spot</p>
                                                <p className={`text-xl font-mono`}>#{spot.parkingSpotNumber}</p>
                                            </div>
                                        )}
                                    </div>
                                ))}
                            </div>
                        ) : (
                            <p className="font-thin text-gray-500 text-center pt-3">No reservations found.</p>
                        )}
                    </div>

                </div>
            </div>
        </section>

    );
}

export default Home;