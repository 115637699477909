import React, { useState } from 'react'
import { AddEmployeeInventory, DeductItemCount } from '../../../FireBase/utils';
import { toast } from 'react-toastify';

function AssignItem({ onsubmit, employees, items }) {
    // State variables for form inputs
    const [selectedEmployee, setSelectedEmployee] = useState(''); // Stores the selected employee ID
    const [selectedItem, setSelectedItem] = useState(null); // Stores the selected item object
    const [assignedQuantity, setAssignedQuantity] = useState(0); // Stores the quantity assigned to the selected item
    const [assignedDate, setAssignedDate] = useState(''); // Placeholder for assigned date (not used in the form yet)
    const date = new Date().toISOString();
    const [loading, setLoading] = useState(false);

    
    const handleSubmit = async (e) => {
        e.preventDefault();
        try{
            setLoading(true);
            await AddEmployeeInventory({
                itemID: selectedItem.id,
                empID: selectedEmployee,
                assignedQuantity: assignedQuantity,
                assignedDate: date,
                returnedDate: null
            }).then(async ()=>{
                await DeductItemCount({
                    itemID: selectedItem.id,
                    quantityToDeduct: assignedQuantity
                })
                setSelectedEmployee("");
                setSelectedItem(null);
                setAssignedQuantity(0);
                onsubmit();
            })

            
        }
        catch(e){
            setLoading(false)
        }
    };

    return (
        <form className='flex flex-col gap-2 w-80' onSubmit={handleSubmit}>
            <h2 className='text-lg font-bold centexTextColour'>Assign Item</h2>

            {/* Label and select input for selecting an employee */}
            <label htmlFor="employee">Select Employee:</label>
            <select
                className='border border-gray-300 rounded p-1'
                id="employee"
                value={selectedEmployee}
                onChange={(e) => setSelectedEmployee(e.target.value)}
                required
            >
                <option value="">Select an employee</option>
                {employees.sort((a, b) => a.firstName.localeCompare(b.firstName)).map(employee => (
                    <option key={employee.id} value={employee.id}>
                        {employee.firstName} {employee.lastName}
                    </option>
                ))}
            </select>

            {/* Label and select input for selecting an item */}
            <label htmlFor="item">Select an Item:</label>
            <select
                className='border border-gray-300 rounded p-1'
                id="item" // Correct ID for the item select input
                value={selectedItem ? selectedItem.id : ''} // Display selected item ID or empty string if none selected
                onChange={(e) => {
                    const item = items.find(item => item.id === e.target.value); // Find the selected item object
                    setSelectedItem(item); // Set the selected item in the state
                }}
                required
            >
                <option value="">Select an Item</option>
                {items.sort((a, b) => a.itemName.localeCompare(b.itemName)).map(item => (
                    <option key={item.id} value={item.id}>
                        {item.itemName + " - " + item.itemQuantity + " Remaining"}
                    </option>
                ))}
            </select>

            {/* Conditionally render the quantity input if an item is selected */}
            <label htmlFor="quantity">Quantity:</label>
            <input
                className='border border-gray-300 rounded p-1'
                type="number"
                id="quantity"
                min={1} // Minimum value for quantity input
                max={selectedItem?.itemQuantity} // Maximum value based on selected item quantity
                value={assignedQuantity} // Controlled component value
                onChange={(e) => setAssignedQuantity(Number(e.target.value))} // Update state on change
                required
            />
            <button
                type="submit"
                className='rounded-md py-2 px-4 hover:scale-105 transition centexBackgroundColour text-white'
                disabled={loading}
            >
                {loading ? "Assigning Item..." : "Assign Item"}
            </button>
        </form>
    )
}

export default AssignItem