import React, { useState } from 'react';
import { AddSelectedUndergroundSpot} from '../FireBase/utils';
import Popup from '../components/UI/Popup';
import AddReservation from '../components/Forms/AddReservation';
import UndergroundParkingSpotsCard from '../components/UndergroundParkingSpotsCard';

function UndergroundParking({ empInfo, date, updatedParkingSpots, parkingEmployees, parkingSpots }) {
    const map = [
        ["ramp", "ramp", "ramp", "ramp", "ramp", "ramp", "ramp", 0, 0, 1],
        ["stairs", "stairs", "stairs", "stairs", "stairs", 0, 0, 0, 0, 2],
        ["stairs", "stairs", "stairs", 0, 28, 32, 0, 0, 0, 3],
        [27, 0, 0, 0, 29, 33, 0, 0, 0, 4],
        [26, 0, 0, 0, 30, 34, 0, 0, 0, 5],
        [25, 0, 0, 0, 31, 35, 0, 0, 0, 6],
        [24, 0, 39, "stairs", "stairs", "stairs", 36, 0, 0, 7],
        [23, 0, 0, "stairs", "Door", "Door", 0, 0, 0, 8],
        [22, 0, 0, 38, 0, 37, 0, 0, 0, 9],
        [21, 0, 0, 0, 0, 0, 0, 0, 0, 10],
        [20, 0, 0, 0, 0, 0, 0, 0, 0, 11],
        [0, 19, 18, 17, 16, 15, 14, 13, 12, 0],
    ];
    const [isReservationPopupOpen, setIsReservationPopupOpen] = useState(false);

    const togglePopup = (setState) => {
        setState(prevState => !prevState);
    };

    return (
        <div className='py-20 section '>
            <h2 className="text-3xl font-bold mb-6">Underground Parking</h2>
            {/* {empInfo?.role === "admin" &&
                <div className='py-4 flex gap-2'>
                    <button className="centexBackgroundColour hover:bg-blue-950 text-white font-bold py-2 px-4 rounded" onClick={() => togglePopup(setIsReservationPopupOpen)}>Add Reservation</button>
                </div>
            } */}

            <div className="grid grid-cols-10">
                {/* Drawing the map */}
                {map.map((row, rowIndex) => (
                    row.map((spotNumber, spotIndex) => {
                        // checks if the spot is Managed by Centex.
                        const spot = updatedParkingSpots.find(spot => spot.parkingSpotNumber === spotNumber);
                        return (
                            <div key={`${rowIndex}-${spotIndex}`} className={``}>
                                {/* render a Managed spot */}
                                {spot ? (
                                    <>
                                    {/* {console.log(spot)} */}
                                    <UndergroundParkingSpotsCard keyVal={spotNumber} date={date} empInfo={empInfo} parkingEmployees={parkingEmployees} spot={spot} />
                                    </>
                                ) :
                                    (
                                        //Render non-managed parking spots on the map.
                                        (spotNumber !== 0) &&
                                        <div
                                            className={` min-h-36 h-full overflow-hidden text-center content-center rounded-lg group relative flex justify-center items-center transition-all font-thin text-xl
                                                    ${typeof spotNumber === "string" ? "bg-gray-400 text-white rounded-none" : "hover:bg-slate-200 shadow-inner bg-zinc-100 border border-zinc-300"}`}
                                        >
                                            {Number.isInteger(spotNumber) && spotNumber}
                                            {Number.isInteger(spotNumber) && (
                                                empInfo?.role === "admin" &&
                                                <button
                                                    className="absolute mx-2 hidden group-hover:block justify-center transition-all p-2 rounded centexBackgroundColour hover:bg-blue-950 text-white hover:scale-105 shadow-2xl font-light text-sm"
                                                    onClick={() => {
                                                        AddSelectedUndergroundSpot({ spotNumber: spotNumber });
                                                    }}
                                                >
                                                    Manage spot
                                                </button>
                                            )}
                                        </div>
                                    )
                                }
                            </div>
                        );
                    })
                ))}
            </div>
            {isReservationPopupOpen && (
                <Popup onClose={() => togglePopup(setIsReservationPopupOpen)}>
                    <AddReservation parkingSpots={parkingSpots} employees={parkingEmployees} onSubmit={() => togglePopup(setIsReservationPopupOpen)} />
                </Popup>
            )}
        </div>
    );
}
export default UndergroundParking;