import React, {useState } from 'react'
import Popup from '../components/UI/Popup';
import AddItem from '../components/Forms/InventoryForms/AddItem';
import ItemCard from '../components/Inventory Components/ItemCard';
import AssignItem from '../components/Forms/InventoryForms/AssignItem';
import EmpInventoryCard from '../components/Inventory Components/EmpInventoryCard';

function Inventory({ empInfo, date, parkingEmployees, items, employeeInventory }) {
    const [isAddItemPopupOpen, setIsAddItemPopupOpen] = useState(false);
    const [isAssignedItemPopupOpen, setAssignedItemPopupOpen] = useState(false);
    const togglePopup = (setState) => {
        setState(prevState => !prevState);
    };

    return (
        <section className='py-20 section text-black'>
            <h2 className="text-3xl font-bold mb-6">Manage Inventory</h2>
            {empInfo?.role === "admin" &&
                <div className='py-4 flex gap-2'>
                    <button className="centexBackgroundColour hover:bg-blue-950 text-white font-bold py-2 px-4 rounded" onClick={() => { togglePopup(setIsAddItemPopupOpen) }}>Add Item</button>
                    <button className="centexBackgroundColour hover:bg-blue-950 text-white font-bold py-2 px-4 rounded" onClick={() => { togglePopup(setAssignedItemPopupOpen) }}>Assign Item</button>
                </div>
            }
            <div className='md:flex gap-5'>
                <div className='w-full'>
                    <h3 className="text-xl font-bold border-b-2">All Inventory</h3>
                    {items.map((item) => (
                        <ItemCard key={item.id} item={item} />
                    ))}
                </div>
                <div className='w-full'>
                    <h3 className="text-xl font-bold border-b-2">Assigned Inventory</h3>
                    {employeeInventory.map((singleEmpInventory) => (
                        <EmpInventoryCard
                            key={singleEmpInventory.id}
                            empInventory={singleEmpInventory}
                            items={items}
                            employees={parkingEmployees}
                        />
                    ))}
                </div>
            </div>

            {isAddItemPopupOpen && (
                <Popup onClose={() => togglePopup(setIsAddItemPopupOpen)}>
                    <AddItem onSubmit={() => togglePopup(setIsAddItemPopupOpen)} />
                </Popup>
            )}
            {isAssignedItemPopupOpen && (
                <Popup onClose={() => togglePopup(setAssignedItemPopupOpen)}>
                    <AssignItem onSubmit={() => togglePopup(setAssignedItemPopupOpen)}
                        employees={parkingEmployees}
                        items={items}
                    />
                </Popup>
            )}
        </section>
    )
}

export default Inventory