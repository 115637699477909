import React, { useState } from 'react'
import AddUser from '../components/Forms/AddUser'
import { deleteEmployee, resetPassword } from '../FireBase/utils';
import { FaEdit, FaTrash } from 'react-icons/fa';
import Popup from '../components/UI/Popup';
import EditUser from '../components/Forms/EditUser';
import { RiRemoteControlFill } from "react-icons/ri";
import { PiPasswordDuotone } from "react-icons/pi";
import { MdEmail } from 'react-icons/md';

function Users({ parkingEmployees, date }) {
  const [loading, setLoading] = useState(false);
  const [userToEdit, setUserToEdit] = useState({})
  const [isUserPopupOpen, setIsUserPopupOpen] = useState(false);
  const [isUserEditPopupOpen, setIsUserEditPopupOpen] = useState(false);
  const [isResetPasswordPopupOpen, SetIsResetPasswordPopupOpen] = useState(false);
  const [isDeleteEmployeePopupOpen, SetIsDeleteEmployeePopupOpen] = useState(false)

  const togglePopup = (setState) => {
    setState(prevState => !prevState);
  };

  return (
    <section className='py-20 section text-black'>
      <h2 className="text-3xl font-bold mb-6">Manage Employees</h2>
      <div className='py-4 flex gap-2'>
        <button className="centexBackgroundColour hover:bg-blue-950 text-white font-bold py-2 px-4 rounded" onClick={() => togglePopup(setIsUserPopupOpen)}>Add Employee</button>
      </div>
      {parkingEmployees
        .sort((a, b) => a.firstName?.localeCompare(b.firstName))
        .map((emp) => (
          <div key={emp.id} className="transition-all my-2 rounded p-5 bg-slate-200 hover:bg-slate-300 flex justify-between items-center shadow-lg">
            <div className='font-thin text-sm'>
              <h2 className='centexTextColour font-bold sm:text-xl sm:w-64 flex gap-2 mb-2'>
                {emp.firstName} {emp.lastName}
                <RiRemoteControlFill
                  title={emp.hasUndergroundRemote ? `Garage Remote provided` : `No Garage Remote`}
                  className={`text-xl sm:text-2xl self-center hover:scale-110
                    ${emp.hasUndergroundRemote ? "text-blue-900" : "text-gray-400"}`}
                />
              </h2>
              <p className='md:text-base flex gap-1'><MdEmail className='self-center text-base centexTextColour' />{emp.email}</p>
              {emp.LastPasswordResetEmail &&
                <p className='md:text-base flex gap-1 cursor-default' title='Last Password Reset Email Date'><PiPasswordDuotone className='self-center centexTextColour text-lg' /> {emp.LastPasswordResetEmail?.month} {emp.LastPasswordResetEmail?.day} {emp.LastPasswordResetEmail?.year}</p>
              }
            </div>
            <div className='flex gap-3 sm:text-2xl'>
              {/* Password Reset Button */}
              <button title='Send Password Reset Email'
                className='hover:text-orange-500 hover:scale-125 centexTextColour transition-all text-xl sm:text-3xl'
                onClick={() => {
                  setUserToEdit({
                    id: emp.id,
                    firstName: emp.firstName,
                    lastName: emp.lastName,
                    hasUndergroundRemote: emp.hasUndergroundRemote ? true : false,
                    email: emp.email
                  });
                  togglePopup(SetIsResetPasswordPopupOpen);
                }}
              >
                <PiPasswordDuotone />
              </button>
              <button title='Edit'
                className='hover:text-orange-500 hover:scale-125 centexTextColour transition-all'
                onClick={() => {
                  togglePopup(setIsUserEditPopupOpen);
                  setUserToEdit({
                    id: emp.id,
                    firstName: emp.firstName,
                    lastName: emp.lastName,
                    hasUndergroundRemote: emp.hasUndergroundRemote ? true : false
                  });
                }}
              >
                <FaEdit />
              </button>
              <button title='Delete' className='hover:text-rose-800 hover:scale-125 centexTextColour transition-all'
                onClick={() => {
                  togglePopup(SetIsDeleteEmployeePopupOpen);
                  setUserToEdit({
                    id: emp.id,
                    firstName: emp.firstName,
                    lastName: emp.lastName,
                    hasUndergroundRemote: emp.hasUndergroundRemote ? true : false,
                    email: emp.email
                  });
                }}
              >
                <FaTrash />
              </button>
            </div>
          </div>
        ))}

      {isUserPopupOpen &&
        <Popup onClose={() => togglePopup(setIsUserPopupOpen)}>
          <AddUser onSubmit={() => togglePopup(setIsUserPopupOpen)} />
        </Popup>
      }
      {isUserEditPopupOpen &&
        <Popup onClose={() => togglePopup(setIsUserEditPopupOpen)}>
          <EditUser onSubmit={() => togglePopup(setIsUserEditPopupOpen)} user={userToEdit} />
        </Popup>
      }
      {isResetPasswordPopupOpen &&
        <Popup onClose={() => togglePopup(SetIsResetPasswordPopupOpen)}>
          <h2 className=' text-lg my-2 font-semibold text-blue-950 w-80'>Send Password Reset Email to</h2>
          <p>{userToEdit.email}</p>
          <button
            onClick={() => {
              setLoading(true);
              resetPassword({ empEmail: userToEdit.email, empID: userToEdit.id, date: date })
                .then(() => togglePopup(SetIsResetPasswordPopupOpen))
                .finally(() => setLoading(false))
            }}
            className='centexBackgroundColour hover:bg-blue-950 text-white font-bold rounded p-2 px-5 mt-10'
          >
            {loading ? "Sending Email..." : "Send Email"}
          </button>
        </Popup>
      }
      {isDeleteEmployeePopupOpen &&
        <Popup onClose={() => togglePopup(SetIsDeleteEmployeePopupOpen)}>
          <h2 className='text-xl mt-2 mb-4 font-semibold text-blue-950 w-80'>Delete {userToEdit.firstName}'s Account</h2>
          <p>This will delete all user's data and prevent them from Logging in.</p>
          <button
            onClick={() => {
              setLoading(true);
              deleteEmployee({ empID: userToEdit.id })
                .then(() => {
                  togglePopup(SetIsDeleteEmployeePopupOpen);
                })
                .finally(() => {
                  setLoading(false);
                });
            }}
            className='centexBackgroundColour hover:bg-rose-800 text-white font-bold rounded p-2 px-5 mt-10'
            disabled={loading}
          >
            {loading ? 'Deleting...' : 'Confirm Delete'}
          </button>
        </Popup>
      }
    </section>
  )
}

export default Users