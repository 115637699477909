import React, { useState } from 'react';
import { AddInventoryItem } from '../../../FireBase/utils';


function AddItem({ onSubmit }) {
    const [itemName, setItemName] = useState("");
    const [itemDiscription, setItemDiscription] = useState("");
    const [itemCategory, setItemCategory] = useState("");
    const [itemQuantity, setItemQuantity] = useState(1);
    const [itemSerialNumber, setItemSerialNumber] = useState("");
    const [loading, setLoading] = useState(false);


    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            setLoading(true)
            await AddInventoryItem({
                itemName: itemName,
                itemDiscription: itemDiscription,
                itemCategory: itemCategory,
                itemQuantity: itemQuantity,
                itemSerialNumber: itemSerialNumber,
            }).then(()=>{
                setItemName("");
                setItemCategory("");
                setItemDiscription("");
                setItemQuantity(1)
                setItemSerialNumber("");
                onSubmit();
            }).finally(()=>{
                setLoading(false);
            })
        } catch (error) {
            setLoading(false)
        }
    };
    return (
        <form className='flex flex-col gap-2 w-80' onSubmit={handleSubmit}>
            <h2 className='text-lg font-bold centexTextColour'>Add Item</h2>
            <label>Item Name:</label>
            <input
                type='text'
                className='border border-gray-300 rounded px-1'
                value={itemName}
                onChange={(e) => setItemName(e.target.value)}
                required
            />
            <label>Serial Number</label>
            <input
                type='text'
                className='border border-gray-300 rounded px-1'
                value={itemSerialNumber}
                onChange={(e) => setItemSerialNumber(e.target.value)}
                required
            />
            <label>Discription</label>
            <input
                type='text'
                className='border border-gray-300 rounded px-1'
                value={itemDiscription}
                onChange={(e) => setItemDiscription(e.target.value)}
            />
            <label>Category</label>
            <input
                type='text'
                className='border border-gray-300 rounded px-1'
                value={itemCategory}
                onChange={(e) => setItemCategory(e.target.value)}
            />
            <label>Quantity</label>
            <input
                type='number'
                className='border border-gray-300 rounded px-1'
                value={itemQuantity}
                onChange={(e) => setItemQuantity(e.target.value)}
            />

            <button
                type="submit"
                className='rounded-md py-2 px-4 hover:scale-105 transition centexBackgroundColour text-white'
                disabled={loading}
            >
                {loading ? "Adding Item..." : "Add Item"}
            </button>
        </form>
    )
}

export default AddItem