import React, { useState } from 'react';
import { registerUser } from '../../FireBase/utils';
import { toast } from 'react-toastify';

function AddUser({ onSubmit }) {
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [loading, setLoading] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            // Add a new document with a generated ID to the "employees" collection
            setLoading(true)
            await registerUser({
                firstName: firstName,
                lastName: lastName,
                email: email,
                password: "123456"
            }).then(() => {
                // Reset form fields after submission
                setFirstName('');
                setLastName('');
                setEmail('');
                // console.log("User added successfully!");
                onSubmit();
            }).finally(() => {
                setLoading(false);
                toast.success("User added successfully!");
            })
        } catch (error) {
            toast.error("Error adding user");
        }
    };

    return (
        <form className='flex flex-col gap-2 w-80' onSubmit={handleSubmit}>
            <h2 className='text-lg font-bold centexTextColour'>Add New Employee</h2>
            <label>First Name:</label>
            <input
                type='text'
                className='border border-gray-300 rounded px-1'
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                required
            />
            <label>Last Name:</label>
            <input
                type='text'
                className='border border-gray-300 rounded px-1'
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                required
            />
            <label>Email Address:</label>
            <input
                type='email'
                className='border border-gray-300 rounded px-1'
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
            />
            <button
                type="submit"
                className='rounded-md py-2 px-4 hover:scale-105 transition centexBackgroundColour text-white'
                disabled={loading}
            >
                {loading ? "Adding User..." : "Add User"}
            </button>
        </form>
    )
}

export default AddUser;
