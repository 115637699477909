import React from 'react';
import {FaRegTimesCircle } from 'react-icons/fa';

const Popup = ({ onClose, children }) => {
    return (
        <div className="fixed z-50 inset-0 overflow-y-auto flex items-center justify-center text-xs lg:text-base">
            <div onClick={onClose}  className="fixed inset-0 centexBackgroundColour opacity-85"></div>
            <div className="relative bg-white rounded-lg overflow-hidden shadow-xl ">
                {/* Close button */}
                <button onClick={onClose} className="absolute top-6 right-6 -mt-4 -mr-4 text-2xl rounded-full p-1 centexTextColour hover:scale-110">
                    <FaRegTimesCircle/>
                </button>

                {/* Content */}
                <div className="p-6 mt-5">
                    {children}
                </div>
            </div>
        </div>
    );
};

export default Popup;
