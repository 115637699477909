import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Authenticate from './Pages/Authenticate';
import UndergroundParking from './Pages/UndergroundParking';
import { auth, db } from './FireBase/firebase.config';
import { useEffect, useState } from 'react';
import Header from './components/Header';
import Users from './Pages/Users';
import Home from './Pages/Home';
import OutdoorParking from './Pages/OutdoorParking';
import { fetchSingleEmployee } from './FireBase/utils';
import { collection, onSnapshot } from 'firebase/firestore';
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import Inventory from './Pages/Inventory';

function App() {

  const [currentUser, setCurrentUser] = useState(false);
  const [empInfo, setEmpInfo] = useState(null);

  const [parkingEmployees, setParkingEmployees] = useState([]);
  const [parkingSpots, setParkingSpots] = useState([]);
  const [reservations, setReservations] = useState([]);
  const [updatedParkingSpots, setUpdatedParkingSpots] = useState([]);
  const [outDoorSpots, setOutdoorSpots] = useState([]);
  const [items, setItems] = useState([]);
  const [employeeInventory, setEmployeeInventory] = useState([]);

  const [online, setOnline] = useState(navigator.onLine);

  const [dataListenersActive, setDataListenersActive] = useState(false);
  const today = new Date();

  const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

  function getCurrentDate() {
    return {
      weekday: daysOfWeek[today.getDay()],
      day: today.getDate(),
      month: months[today.getMonth()],
      year: today.getFullYear()
    };
  }
  const currentDate = getCurrentDate();
  const beginningOfNextDay = new Date();
  beginningOfNextDay.setDate(beginningOfNextDay.getDate() + 1);
  beginningOfNextDay.setHours(0, 0, 0); // Set time to the beginning of the next day

  //Listens to device's internect status and sets the online state to true or false.
  useEffect(() => {
    const handleOnline = () => setOnline(true);
    const handleOffline = () => setOnline(false);

    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  },);

  // Listen to users auth status
  useEffect(() => {
    const authListener = auth.onAuthStateChanged(async (user) => {
      setCurrentUser(user);
      if (user) {
        try {
          const info = await fetchSingleEmployee({ empID: user.uid });
          setEmpInfo(info);
          setDataListenersActive(true); // Activate data listeners
        } catch (error) {
          console.error('Error fetching employee info:', error);
        }
      } else {
        setEmpInfo(null);
        setDataListenersActive(false); // Deactivate data listeners
      }
    });

    return () => {
      authListener(); // Unsubscribe from the auth state change listener
    };
  }, [parkingEmployees, online]);

  //Firebase Data Listeners 
  useEffect(() => {

    if (!currentUser) return
    if (!dataListenersActive) return; // Check if data listeners are active

    const unsubscribeEmployees = onSnapshot(collection(db, 'Employees'), (snapshot) => {
      const employeesData = snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
      setParkingEmployees(employeesData);
      //console.log("Employees updates");
    });
    const unsubscribeSpots = onSnapshot(collection(db, 'UndergroundParkingSpots'), (snapshot) => {
      const spotsData = snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
      setParkingSpots(spotsData);
      //console.log("UndergroundParkingSpots updated");

    });
    const unsubscribeReservations = onSnapshot(collection(db, 'Reservations'), (snapshot) => {
      const reservationsData = snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
      setReservations(reservationsData);
      //console.log("Reservations updated");
    });
    const unsubscribeOutdoorSpots = onSnapshot(collection(db, 'OutdoorParkingSpots'), (snapshot) => {
      const reservationsData = snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
      setOutdoorSpots(reservationsData);
      //console.log("OutdoorParkingSpots updated");
    });
    const unscribeItems = onSnapshot(collection(db, "Items"), (snapshot)=>{
      const itemsData = snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
      setItems(itemsData);
    })
    const unscribeEmployeeInventory = onSnapshot(collection(db, "EmployeeInventory"), (snapshot)=>{
      const Data = snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
      setEmployeeInventory(Data);
    })

    return () => {
      unsubscribeEmployees();
      unsubscribeSpots();
      unsubscribeReservations();
      unsubscribeOutdoorSpots();
      unscribeItems();
      unscribeEmployeeInventory();
    };
  }, [dataListenersActive, online]);

  //sets underground updatedParkingSpots with the data being fetched from the data listeners.
  //Formulates aditional data from the database for the app to use. 
  //runs when the listeners update  
  useEffect(() => {
    setUpdatedParkingSpots(parkingSpots.map(spot => {
      const spotReservations = reservations.filter(res => res.parkingSpotId === spot.id);
      let spotAvailableToday = true; // Reset spotAvailableToday for each spot

      //if reservations under a spot
      if (spotReservations.length > 0) {
        let daysReserved = [];
        // loop through reservations of the spot and return a reserved under array containing reservation info of all reservations. 
        const reservedUnder = spotReservations.map(res => {
          const employee = parkingEmployees.find(emp => emp.id === res.employeeId);
          // Reservation paused today checker. if todays date falls within pause start and end dates, set reservation paused today to true.
          let reservationPausedToday = false;
          if ((today.getFullYear() >= res.pauseStart?.year && today.getMonth() >= res.pauseStart?.month - 1 && today.getDate() >= res.pauseStart?.day)
            &&
            ((today.getFullYear() <= res.pauseEnd?.year && today.getMonth() <= res.pauseEnd?.month - 1 && today.getDate() <= res.pauseEnd?.day))
          ) {
            reservationPausedToday = true;
          }
          // If the reservation is for today set spot available today to false.
          if (res.daysOfWeek?.includes(currentDate.weekday) && reservationPausedToday !== true) {
            spotAvailableToday = false;
          }

          if (res.daysOfWeek?.includes) { }

          daysReserved = res.daysOfWeek.concat(daysReserved)

          if (employee) {
            return { empID: employee.id, employee: `${employee.firstName}`, employeeHasUndergroundRemote: employee.hasUndergroundRemote, daysOfWeek: res.daysOfWeek, startDate: res.startDate, endDate: res.endDate, reservationID: res.id, deleteEndOfDay: res.deleteEndOfDay, reservationPausedToday };
          } else {
            return { employee: "Employee Not Found", daysOfWeek: res.daysOfWeek, startDate: res.startDate, endDate: res.endDate, reservationID: res.id, deleteEndOfDay: res.deleteEndOfDay };
          }
        });

        return { ...spot, reserved: true, reservedUnder, spotAvailableToday, daysReserved };
      } else {
        return { ...spot, reserved: false, reservedUnder: [], spotAvailableToday: true, };
      }
    }));
  }, [parkingSpots, reservations, parkingEmployees,]);

  return (
    <div className="App">
      <ToastContainer
        position="top-left"
        autoClose={4000}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        draggable
        pauseOnHover
        theme="dark"
      />
      <Router>
        {currentUser ? (
          <>
            <Header empInfo={empInfo} />
            <Routes>
              <Route path='/'
                element={
                  <Home
                    empInfo={empInfo}
                    date={currentDate}
                    parkingEmployees={parkingEmployees}
                    updatedParkingSpots={updatedParkingSpots}
                    outDoorSpots={outDoorSpots}
                  />
                }
              />
              <Route path='/GarageParking/'
                element={
                  <UndergroundParking
                    empInfo={empInfo}
                    date={currentDate}
                    parkingEmployees={parkingEmployees}
                    parkingSpots={parkingSpots}
                    updatedParkingSpots={updatedParkingSpots}
                  />
                }
              />
              <Route path='/OutdoorParking/'
                element={
                  <OutdoorParking
                    empInfo={empInfo}
                    date={currentDate}
                    parkingEmployees={parkingEmployees}
                    outDoorSpots={outDoorSpots}
                  />
                }
              />
              <Route path='/employees/' element={
                <Users
                  empInfo={empInfo}
                  date={currentDate}
                  parkingEmployees={parkingEmployees}
                />
              }
              />
              <Route path='/inventory/' element={
                <Inventory
                  empInfo={empInfo}
                  date={currentDate}
                  parkingEmployees={parkingEmployees}
                  items={items}
                  employeeInventory={employeeInventory}
                />
              }
              />
            </Routes>
          </>
        ) : (
          <>
            <Authenticate />
          </>
        )}
      </Router>

    </div>
  );
}

export default App;