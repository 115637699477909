import React, { useEffect, useState } from 'react';

function Offline({ children }) {
    const [online, setOnline] = useState(navigator.onLine);

    useEffect(() => {
        const handleOnline = () => setOnline(true);
        const handleOffline = () => setOnline(false);

        window.addEventListener('online', handleOnline);
        window.addEventListener('offline', handleOffline);

        return () => {
            window.removeEventListener('online', handleOnline);
            window.removeEventListener('offline', handleOffline);
        };
    }, []);

    return (
        <>
            {!online && (
                <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-50 z-50">
                    <div className="bg-white rounded-lg p-6">
                        <h2 className="text-lg font-semibold mb-2">You're Offline</h2>
                        <p className="text-sm">Check your internet connection.</p>
                    </div>
                </div>
            )}
            {children}
        </>
    )
}

export default Offline;
