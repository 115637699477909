import React from 'react';
import { auth } from '../../FireBase/firebase.config';
import { useNavigate } from 'react-router-dom';

const LogoutBtn = () => {
    const navigate = useNavigate();

    const handleLogout = async () => {
        try {
            await auth?.signOut();
            // Redirect to the login page after logout
            navigate('/authenticate');
        } catch (error) {
            console.error('Error logging out:', error);
        }
    };

    return (
        <button onClick={handleLogout} className="hover:text-red-700 hover:scale-105">
            Logout
        </button>
    );
};

export default LogoutBtn;
