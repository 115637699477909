import { getDocs, collection, getDoc, doc, deleteDoc, addDoc, updateDoc } from 'firebase/firestore';
import { db, auth } from './firebase.config';
import { sendPasswordResetEmail } from "firebase/auth";
import { getFunctions, httpsCallable } from "firebase/functions";
import { toast } from 'react-toastify';

const functions = getFunctions();
//Employee Utilities
export const fetchEmployees = async () => {
    try {
        const employeeSnapshot = await getDocs(collection(db, 'Employees'));
        const employeeData = employeeSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        return employeeData;
    } catch (error) {
        console.error('Error fetching employees:', error);
        throw error;
    }
};
export const fetchSingleEmployee = async ({ empID }) => {
    try {
        const userDoc = await getDoc(doc(db, "Employees", empID));
        const userData = userDoc.data();
        userData.empID = empID; // Add empID to the userData object
        return userData;
    } catch (error) {
        console.error('Error fetching Employee:', error);
        throw error;
    }
}
export const registerUser = async ({ email, password, firstName, lastName }) => {
    const registerUser = httpsCallable(functions, 'registerUser')
    try {
        await registerUser({
            firstName: firstName,
            lastName: lastName,
            email: email,
            password: password
        })
        return "User Registered";
    }
    catch (error) {
        console.error("Error Registering User.")
    }
}
export const deleteEmployee = async ({ empID, empEmail }) => {
    const deleteUser = httpsCallable(functions, 'deleteUser');
    console.log(empID);
    console.log(empEmail);
    try {
        await deleteUser({
            uid: empID,
            email: empEmail
        }).then(() => {
            toast.success("User Deleted")
            return "User Deleted"
        })
    } catch (error) {
        toast.error('Error Deleting Employee');
    }
}
export const resetPassword = async ({ empEmail, empID, date }) => {
    try {
        await sendPasswordResetEmail(auth, empEmail)
            .then(async () => {
                await updateDoc(doc(db, "Employees", empID), {
                    LastPasswordResetEmail: date
                }).then(() => {
                    return "Password Reset Email Send"
                });
            })
    } catch (error) {
        console.error("Error sending password reset Email")
    }
}

//Reservation Utilities
export const fetchReservations = async () => {
    try {
        const reservationsSnapShot = await getDocs(collection(db, 'Reservations'));
        const reservationsData = reservationsSnapShot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        return reservationsData;
    } catch (error) {
        console.error('Error fetching reservations:', error);
        throw error;
    }
};
export const fetchOutdoorReservations = async () => {
    try {
        const reservationsSnapShot = await getDocs(collection(db, 'OutdoorParkingSpots'));
        const reservationsData = reservationsSnapShot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        return reservationsData;
    } catch (error) {
        console.error('Error fetching reservations:', error);
        throw error;
    }
};
export const deleteOutdoorReservation = async ({ resID }) => {
    try {
        await deleteDoc(doc(db, "OutdoorParkingSpots", resID)).then(() => {
            toast.success("Reservation Deleted");
            return "Deleted";
        })

    }
    catch (error) {
        toast.error('Error Deleting Reservation')
        throw error;
    }

}
export const deleteReservation = async ({ resID }) => {
    try {
        await deleteDoc(doc(db, "Reservations", resID)).then(() => {
            toast.success("Reservation Deleted");
            return "Deleted";
        })
    }
    catch (error) {
        console.error('Error Deleting Reservation:', error)
        throw error;
    }

}
export const editOutdoorReservation = async ({ resID, employeeName }) => {
    try {

    }
    catch (error) {
        console.error("Error Editing Outdoor reservation", error);
        throw error;
    }
}
export const resumeUndergroundReservation = async (resID) => {
    try {
        await updateDoc(doc(db, 'Reservations', resID), {
            pauseStart: {
                year: null,
                month: null,
                day: null,
            },
            pauseEnd: {
                year: null,
                month: null,
                day: null,
            },
        }).then(() => {
            return "Reservation resumed"
        })
    } catch (error) {
        console.error('Error resuming underground reservation' + error)
    }
}

//Parking Spot Utilities
export const AddSelectedUndergroundSpot = async ({ spotNumber }) => {
    try {
        await addDoc(collection(db, "UndergroundParkingSpots"), {
            parkingSpotNumber: spotNumber,
            timestamp: new Date().toISOString(),
        })
    }
    catch (error) {
        console.error("Error Editing Outdoor reservation", error);
        throw error;
    }
}
export const DeleteSelectedUndergroundSpot = async ({ spotID }) => {
    try {
        await deleteDoc(doc(db, "UndergroundParkingSpots", spotID)).then(() => {
            toast.success("Reservation Deleted");
            return "Deleted";
        })
    }
    catch (error) {
        console.error('Error Deleting Parking Spot:', error)
        throw error;
    }
}
export const fetchParkingSpots = async () => {
    try {
        const parkingSpotSnapshot = await getDocs(collection(db, 'UndergroundParkingSpots'));
        const parkingSpotData = parkingSpotSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        return parkingSpotData;
    } catch (error) {
        console.error('Error fetching parking spots:', error);
        throw error;
    }
};
export const reserveUndergroundSpotForDay = async ({ spotID, EmpId, date }) => {

    try {
        await addDoc(collection(db, 'Reservations'), {
            employeeId: EmpId,
            parkingSpotId: spotID,
            daysOfWeek: [date.weekday],
            startDate: date.year + "-" + date.month + "-" + date.day,
            endDate: date.year + "-" + date.month + "-" + date.day,
            timestamp: new Date().toISOString(),
            deleteEndOfDay: true,
        });

    } catch (error) {
        console.error('Error reserving underground parking spot: ', error)
        throw error;
    }
};

//Items uitlity
// Add Item
export const AddInventoryItem = async ({ itemName, itemDiscription, itemCategory, itemQuantity, itemSerialNumber }) => {
    try {
        await addDoc(collection(db, 'Items'), {
            itemName: itemName,
            itemDiscription: itemDiscription,
            itemCategory: itemCategory,
            itemQuantity: itemQuantity,
            itemSerialNumber: itemSerialNumber
        })
            .then(() => {
                toast.success("Item Added");
            })
    }
    catch (error) {
        console.error("Error Adding Item", error);
        toast.error("Error Adding Item")
        throw error;
    }
}
// delete Item
export const DeleteInventoryItem = async ({ itemID }) => {
    try {
        await deleteDoc(doc(db, "Items", itemID)).then(() => {
            toast.success("Item Deleted");
            return "Deleted";
        })
    }
    catch (error) {
        console.error('Error Deleting Item:', error)
        toast.error("Error Deleting Item")
    }
}
// Fetch Items
export const fetchItems = async () => {
    try {
        const itemsSnapshot = await getDocs(collection(db, 'Items'));
        const itemsSnapshotData = itemsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        return itemsSnapshotData;
    } catch (error) {
        console.error("Error fetching Items", error);
        toast.error("Error Fetching Item")
        throw error
    }
}
// Deduct Item Count
export const DeductItemCount = async ({ itemID, quantityToDeduct }) => {
    try {
        const itemRef = doc(db, 'Items', itemID); // Use itemID to reference the document
        const itemSnap = await getDoc(itemRef);

        if (itemSnap.exists()) {
            const currentQuantity = itemSnap.data().itemQuantity;

            if (quantityToDeduct > currentQuantity) {
                toast.error("Insufficient Quantity");
                return;
            }

            const newQuantity = currentQuantity - quantityToDeduct;

            await updateDoc(itemRef, {
                itemQuantity: newQuantity
            });

            toast.success("Item Quantity Deducted");
        } else {
            toast.error("Item Not Found");
        }
    } catch (error) {
        console.error("Error Deducting Item Quantity", error);
        toast.error("Error Deducting Item Quantity");
        throw error;
    }
};
export const AddItemCount = async ({ itemID, quantityToAdd }) => {
    try {
        const itemRef = doc(db, 'Items', itemID); // Use itemID to reference the document
        const itemSnap = await getDoc(itemRef);

        if (itemSnap.exists()) {
            const currentQuantity = itemSnap.data().itemQuantity;
            const newQuantity = currentQuantity + quantityToAdd;

            await updateDoc(itemRef, {
                itemQuantity: newQuantity
            });

            toast.success("Item Quantity Added");
        } else {
            toast.error("Item Not Found");
        }
    } catch (error) {
        console.error("Error Adding Item Quantity", error);
        toast.error("Error Adding Item Quantity");
    }
}

//Employee Inventory Utilities
// Add Employee Inventory
export const AddEmployeeInventory = async ({ itemID, empID, assignedQuantity, assignedDate, returnedDate }) => {
    try {
        await addDoc(collection(db, 'EmployeeInventory'), {
            itemID: itemID,
            empID: empID,
            assignedQuantity: assignedQuantity,
            assignedDate: assignedDate,
            returnedDate: returnedDate,
        })
            .then(() => {
                toast.success("Inventory Assigned");
            })
    }
    catch (error) {
        console.error("Error Adding Item", error);
        toast.error("Error Adding Item")
        throw error;
    }
}
// Delete Employee Inventory
export const deleteEmployeeInventory = async ({ empInventoryID }) => {
    try {
        await deleteDoc(doc(db, "EmployeeInventory", empInventoryID)).then(() => {
            toast.success("Employee Inventory Deleted");
            return "Deleted";
        })
    }
    catch (error) {
        console.error('Error Deleting Employee Inventory:', error)
        toast.error("Error Deleting Employee Inventory")
    }
}