import { doc, updateDoc } from 'firebase/firestore';
import React, { useState } from 'react'
import { db } from '../../FireBase/firebase.config';

function PauseReservation({ onSubmit, reservation }) {
    const [pauseStartDate, setPauseStartDate] = useState('');
    const [pauseEndDate, setPauseEndDate] = useState('');
    const [loading, setLoading] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            // Edit Documnet in Firestore collection
            setLoading(true);
            const pauseStartDateSeperated = pauseStartDate.split("-");
            const pauseEndDateSeperated = pauseEndDate.split("-");
            await updateDoc(doc(db, 'Reservations', reservation.resID), {
                pauseStart: {
                    year: parseInt(pauseStartDateSeperated[0], 10),
                    month: parseInt(pauseStartDateSeperated[1], 10),
                    day: parseInt(pauseStartDate[2], 10)
                },
                pauseEnd: {
                    year: parseInt(pauseEndDateSeperated[0], 10),
                    month: parseInt(pauseEndDateSeperated[1], 10),
                    day: parseInt(pauseEndDateSeperated[2], 10)
                },
            }).then(()=>{
                onSubmit();
                setPauseStartDate('');
                setPauseEndDate("");
                setLoading(false);
            })
        } catch (error) {
            console.error('Error adding document: ', error);
        }
    };
    return (
        <form className='flex flex-col gap-2 w-80' onSubmit={handleSubmit}>
            <h2 className='text-lg font-semibold centexTextColour'>Pause {reservation.resEmp}'s Reservation under Spot {reservation.spotNumber}:</h2>
            <h2 className='font-semibold centexTextColour bg-slate-200 border p-1 rounded'>Reserved under {reservation.resEmp} on <br /> <strong className='font-thin text-sm'>{reservation.resDaysOfWeek.join(', ')}</strong></h2>
            
                <label>Pause Duration Start:</label>
                <input
                    type='date'
                    className='border border-gray-300 rounded px-1'
                    value={pauseStartDate}
                    onChange={(e) => setPauseStartDate(e.target.value)}
                    required
                />
                <label>Pause Duration End:</label>
                <input
                    type='date'
                    className='border border-gray-300 rounded px-1'
                    value={pauseEndDate}
                    onChange={(e) => setPauseEndDate(e.target.value)}
                    required
                />
            
            <button
                type="submit"
                className='rounded-md py-2 px-4 hover:scale-105 transition centexBackgroundColour text-white'
            >
                {loading ? 'Pausing Reservation...' : 'Pause Reservation'}
            </button>
        </form>
    )
}

export default PauseReservation