import React, { useState } from 'react';
import Popup from '../components/UI/Popup';
import { DeleteSelectedUndergroundSpot } from '../FireBase/utils';
import AddSpotReservation from '../components/Forms/AddSpotReservation';
import { FaPlus, FaTrash } from 'react-icons/fa';
import UndergroundReservationCard from '../components/UndergroundReservationCard';

function UndergroundParkingSpotsCard({ empInfo, date, parkingEmployees, spot, keyVal }) {

    const [isSpotReservationOpen, setIsSpotReservationOpen] = useState(false);
    const [spotToReserve, setSpotToReserve] = useState({});

    const togglePopup = (setState) => {
        setState(prevState => !prevState);
    };

    return (
        <div key={keyVal} className={`border border-zinc-400 sm:p-1 h-full w-full bg-slate-300 content-center text-center rounded-lg overflow-hidden`}>
            <h2 className={`font-extrabold text-xl font-mono  ${spot.spotAvailableToday ? "text-green-800" : "text-rose-800"}`}>
                {spot.parkingSpotNumber}
            </h2>
            {spot.reserved && (
                <div className={spot.reservedUnder.length > 1 ? `grid grid-flow-col-1 md:grid-cols-2 gap-1` : ""}>
                    {spot.reservedUnder.map((reservation) => (
                        <UndergroundReservationCard key={reservation.reservationID} date={date} empInfo={empInfo} parkingEmployees={parkingEmployees} reservation={reservation} keyVal={reservation.reservationID} spot={spot} />
                    ))}

                </div>
            )}

            {/* Parking Spot Action Buttons. */}
            {empInfo?.role === "admin" &&
                <div className='pt-2 text-lg font-bold'>
                    <button
                        className='centexTextColour float align-middle hover:scale-125 transition-all'
                        onClick={() => {
                            setSpotToReserve({ spotID: spot.id, spotNumber: spot.parkingSpotNumber });
                            togglePopup(setIsSpotReservationOpen);
                        }}
                        title='Add New Reservation'
                    >
                        <FaPlus />
                    </button>
                    {!spot.reserved &&
                        <button
                            className='text-rose-800 float align-middle hover:scale-125 transition-all'
                            onClick={() => {
                                DeleteSelectedUndergroundSpot({ spotID: spot.id });
                            }}
                        >
                            <FaTrash />
                        </button>
                    }
                </div>
            }
            {/* Popups */}
            {isSpotReservationOpen && (
                <Popup onClose={() => togglePopup(setIsSpotReservationOpen)}>
                    <AddSpotReservation
                        selectedParkingSpotNumber={spotToReserve.spotNumber}
                        employees={parkingEmployees}
                        selectedParkingSpot={spotToReserve.spotID}
                        daysReserved={spot.daysReserved}
                        onSubmit={() => togglePopup(setIsSpotReservationOpen)}
                    />
                </Popup>
            )}


        </div>
    )
}

export default UndergroundParkingSpotsCard