// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getFunctions } from "firebase/functions";

const firebaseConfig = {
  apiKey: "AIzaSyAMiRDFu0Qc4788X66w4PKhC9pUTUrcnYs",
  authDomain: "parking-manager-7cdaf.firebaseapp.com",
  projectId: "parking-manager-7cdaf",
  storageBucket: "parking-manager-7cdaf.appspot.com",
  messagingSenderId: "38764927017",
  appId: "1:38764927017:web:8ab84280946524a843e438",
  measurementId: "G-CXNLXM2S2Q"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);
export const functions = getFunctions(app);
export default app;