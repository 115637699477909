import { doc, updateDoc } from 'firebase/firestore';
import React, { useState } from 'react'
import { db } from '../../FireBase/firebase.config';

function EditUser({ user, onSubmit }) {
    const [firstName, setFirstName] = useState(user.firstName);
    const [lastName, setLastName] = useState(user.lastName);
    const [hasUndergroundRemote, setHasUndergroundRemote] = useState(user.hasUndergroundRemote ? true : false)

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            // Add a new document with a generated ID to the "employees" collection
            await updateDoc(doc(db, "Employees", user.id), {
                firstName: firstName,
                lastName: lastName,
                hasUndergroundRemote: hasUndergroundRemote
            });
            console.log("User Edited successfully!");
            onSubmit();
        } catch (error) {
            console.error("Error Editing user: ", error);
        }
    };

    return (
        <form className='flex flex-col gap-2 w-80' onSubmit={handleSubmit}>
            <h2 className='text-lg font-bold centexTextColour'>Edit Employee: {user.firstName}</h2>
            <label>First Name:</label>
            <input
                type='text'
                className='border border-gray-300 rounded px-1'
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                required
            />
            <label>Last Name:</label>
            <input
                type='text'
                className='border border-gray-300 rounded px-1'
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                required
            />
            <div className='flex gap-3'>
                <label>Garage Remote:</label>
                <input
                    type='checkbox'
                    className='border border-gray-300 rounded px-1 w-4'
                    checked={hasUndergroundRemote} // Set checked attribute based on hasUndergroundRemote value
                    onChange={(e) => setHasUndergroundRemote(e.target.checked)} // Update hasUndergroundRemote with the checked state
                />
            </div>
            <button
                type="submit"
                className='rounded-md py-2 px-4 hover:scale-105 transition centexBackgroundColour text-white'
            >
                Edit User
            </button>
        </form>
    )
}

export default EditUser