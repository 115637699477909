import React, {useState } from 'react';
import { collection, addDoc } from 'firebase/firestore';
import { db } from '../../FireBase/firebase.config';

function ReserveOutdoorSpot({onSubmit, employees }) {
  // State variables to store form data
  const [selectedEmployee, setSelectedEmployee] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [parkingSpotNumber, setParkingSpotNumber] = useState('');

  // Function to handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      // Add document to Firestore collection
      await addDoc(collection(db, 'OutdoorParkingSpots'), {
        employeeId: selectedEmployee,
        parkingSpotNumber: parkingSpotNumber,
        startDate: startDate,
        endDate: endDate,
        timestamp: new Date().toISOString()
      });
      console.log('Document written');

      // Reset form fields after submission
      setParkingSpotNumber('');
      setSelectedEmployee('');
      setEndDate("");
      setStartDate("");
      onSubmit();
    } catch (error) {
      console.error('Error adding document: ', error);
    }
  };

  return (
    <form className='flex flex-col gap-2 w-80' onSubmit={handleSubmit}>
      <h1>Reserve Outdoor Parking Spot</h1>
      <label htmlFor="employee">Select Employee:</label>
      <select
        className='border border-gray-300 rounded px-1'
        id="employee"
        value={selectedEmployee}
        onChange={(e) => setSelectedEmployee(e.target.value)}
        required
      >
        <option value="">Select an employee</option>
        {employees.sort((a, b) => a.firstName.localeCompare(b.firstName)).map(employee => (
          <option key={employee.id} value={employee.id}>{employee.firstName} {employee.lastName}</option>
        ))}
      </select>
      <label>Outdoor Parking Spot Number:</label>
      <input
        type='number'
        className='border border-gray-300 rounded px-1'
        value={parkingSpotNumber}
        onChange={(e) => setParkingSpotNumber(e.target.value)}
        required
      />
      <label>Start Date:</label>
      <input
        type='date'
        className='border border-gray-300 rounded px-1'
        value={startDate}
        onChange={(e) => setStartDate(e.target.value)}
        required
      />
      <label>End Date:</label>
      <input
        type='date'
        className='border border-gray-300 rounded px-1'
        value={endDate}
        onChange={(e) => setEndDate(e.target.value)}
        required
      />
      <button
        type="submit"
        className='rounded-md py-2 px-4 hover:scale-105 transition centexBackgroundColour text-white'
      >
        Add Parking Spot
      </button>
    </form>
  );
}

export default ReserveOutdoorSpot;
