import React, { useState } from 'react'
import { FaEdit, FaTrash } from 'react-icons/fa'
import { AddItemCount, deleteEmployeeInventory, DeleteInventoryItem } from '../../FireBase/utils'

function EmpInventoryCard({ empInventory, items, employees }) {
    const employee = employees.find(emp => emp.id === empInventory?.empID);
    const item = items.find(item => item.id === empInventory?.itemID)

    return (
        <div key={empInventory.id} className="transition-all my-2 rounded p-5 bg-slate-200 hover:bg-slate-300 flex justify-between items-center shadow-lg">
            <div className='w-full'>
                <h2 className='centexTextColour font-bold sm:text-xl sm:w-64 flex gap-2 mb-2'>
                    {employee.firstName + " " + employee.lastName}
                </h2>
                {item ?
                    <div className='bg-slate-50 p-3 rounded w'>
                        <p className='md:text-base flex gap-1'>Assigned Quantity: {empInventory.assignedQuantity}</p>
                        <p className='md:text-base flex gap-1'>{item?.itemName}</p>
                        <p className='md:text-base flex gap-1'>{item?.itemSerialNumber}</p>
                        <p className='md:text-base flex gap-1'>{item?.itemCategory}</p>
                    </div> :
                    <div className='bg-slate-50 p-3 rounded w'>Item Not Found</div>
                }
            </div>
            {/* Options */}
            <div className='flex gap-3 sm:text-2xl pl-4'>
                <button title='Delete' className='hover:text-rose-800 hover:scale-125 centexTextColour transition-all'
                    onClick={async () => {
                        await deleteEmployeeInventory({ empInventoryID: empInventory.id })
                            .then(
                                AddItemCount({ itemID: item?.id, quantityToAdd: empInventory.assignedQuantity })
                            )
                    }}
                >
                    <FaTrash />
                </button>
            </div>
        </div>
    )
}

export default EmpInventoryCard