import React, {useState } from 'react';
import ReserveOutdoorSpot from '../components/Forms/ReserveOutdoorSpot';
import { deleteOutdoorReservation } from '../FireBase/utils'; // Remove fetchEmployees import
import Popup from '../components/UI/Popup';
import { FaEdit, FaTrash } from 'react-icons/fa';
import EditOutdoorReservation from '../components/Forms/EditOutdoorReservation';

function OutdoorParking({ empInfo, outDoorSpots, parkingEmployees }) {
    const [isReservationPopupOpen, setIsReservationPopupOpen] = useState(false);
    const [isEditReservationPopupOpen, setIsEditReservationPopupOpen] = useState(false);
    const [stoptoEdit, setSpotToEdit] = useState({});

    const togglePopup = (setState) => {
        setState((prevState) => !prevState);
    };

    return (
        <div className='section py-20'>
            <h2 className="text-3xl font-bold mb-6">Outdoor Parking</h2>
            {empInfo?.role === "admin" &&
                <div className='py-4 flex gap-2'>
                    <button className="centexBackgroundColour hover:bg-blue-950 text-white font-bold py-2 px-4 rounded" onClick={() => togglePopup(setIsReservationPopupOpen)}>Add Reservation</button>
                </div>
            }
            {isReservationPopupOpen && (
                <Popup onClose={() => togglePopup(setIsReservationPopupOpen)}>
                    <ReserveOutdoorSpot onSubmit={() => togglePopup(setIsReservationPopupOpen)} employees={parkingEmployees} />
                </Popup>
            )}
            {/* convert the string parking spot number to a ineger and sort array by assending order*/}
            {outDoorSpots?.sort((a, b) => parseInt(a.parkingSpotNumber) - parseInt(b.parkingSpotNumber)).map(spot => (
                <div key={spot.id} className="my-2 rounded p-5 bg-slate-200 hover:bg-slate-300 transition-all flex justify-between items-center shadow-lg">
                    <div className='flex text-lg'>
                        <h2 className='centexTextColour font-extrabold'>Spot {spot.parkingSpotNumber}</h2>
                        <h2 className='px-3'>{parkingEmployees.find(emp => emp.id === spot.employeeId)?.firstName} {parkingEmployees.find(emp => emp.id === spot.employeeId)?.lastName}</h2>
                    </div>
                    {empInfo?.role === "admin" &&
                        <div className='flex gap-2 text-2xl'>
                            <button title='Edit'
                                className='hover:text-orange-500 hover:scale-125 centexTextColour transition-all'
                                onClick={() => {
                                    togglePopup(setIsEditReservationPopupOpen)
                                    setSpotToEdit({
                                        parkingSpotNumber: spot.parkingSpotNumber,
                                        ParkingSpotID: spot.id
                                    })
                                }}
                            >
                                <FaEdit />
                            </button>
                            {isEditReservationPopupOpen && (
                                <Popup onClose={() => { togglePopup(setIsEditReservationPopupOpen) }}>
                                    <EditOutdoorReservation spot={stoptoEdit} employees={parkingEmployees} onSubmit={() => togglePopup(setIsEditReservationPopupOpen)} />
                                </Popup>
                            )}
                            <button title='Delete' className='hover:text-rose-800 hover:scale-125 centexTextColour transition-all' onClick={() => { deleteOutdoorReservation({ resID: spot.id }); }}>
                                <FaTrash />
                            </button>
                        </div>
                    }
                </div>
            ))}
        </div>
    )
}

export default OutdoorParking;
