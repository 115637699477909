import React, { useState } from 'react';
import { collection, addDoc } from 'firebase/firestore';
import { db } from '../../FireBase/firebase.config';
import { toast } from 'react-toastify';

function AddReservation({onSubmit, employees, parkingSpots }) {
    const [selectedEmployee, setSelectedEmployee] = useState('');
    const [selectedParkingSpot, setSelectedParkingSpot] = useState('');
    const [selectedDaysOfWeek, setSelectedDaysOfWeek] = useState([]);
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();
        // Add reservation to "Reservations" collection in Firestore
        try {
            await addDoc(collection(db, 'Reservations'), {
                employeeId: selectedEmployee,
                parkingSpotId: selectedParkingSpot,
                daysOfWeek: selectedDaysOfWeek,
                startDate: startDate,
                endDate: endDate,
                timestamp: new Date().toISOString()
            }).then(()=>{
                toast.success("Reservation Added");
            });
            console.log("Reservation added successfully!");
            // Reset form fields after successful submission
            setSelectedEmployee('');
            setSelectedParkingSpot('');
            setEndDate("");
            setStartDate("");
            setSelectedDaysOfWeek([]);
            onSubmit();
        } catch (error) {
            console.error("Error adding reservation: ", error);
        }
    };

    const handleDayOfWeekChange = (day, isChecked) => {
        if (isChecked) {
            setSelectedDaysOfWeek(prevDays => [...prevDays, day]);
        } else {
            setSelectedDaysOfWeek(prevDays => prevDays.filter(d => d !== day));
        }
    };

    return (
        <form className='flex flex-col gap-2 w-80' onSubmit={handleSubmit}>
            <h2>Reserve Underground Parking Spot</h2>
            <label htmlFor="employee">Select Employee:</label>
            <select
                className='border border-gray-300 rounded px-1'
                id="employee"
                value={selectedEmployee}
                onChange={(e) => setSelectedEmployee(e.target.value)}
                required
            >
                <option value="">Select an employee</option>
                {employees.sort((a, b) => a.firstName.localeCompare(b.firstName)).map(employee => (
                    <option key={employee.id} value={employee.id}>{employee.firstName} {employee.lastName}</option>
                ))}
            </select>
            <label htmlFor="parkingSpot">Select an underground parking spot:</label>
            <select
                className='border border-gray-300 rounded px-1'
                id="parkingSpot"
                value={selectedParkingSpot}
                onChange={(e) => setSelectedParkingSpot(e.target.value)}
                required
            >
                <option value="">Select a parking spots</option>
                {parkingSpots.map(parkingSpot => (
                    <option key={parkingSpot.id} value={parkingSpot.id}>{parkingSpot.parkingSpotNumber}</option>
                ))}
            </select>
            <div>
                <label>Select Days of the Week:</label>
                <div className="flex flex-wrap gap-2">
                    {["Monday", "Tuesday", "Wednesday", "Thursday", "Friday"].map(day => (
                        <label key={day} className="flex items-center gap-1">
                            <input
                                type="checkbox"
                                value={day}
                                checked={selectedDaysOfWeek.includes(day)}
                                onChange={(e) => handleDayOfWeekChange(day, e.target.checked)}
                            />
                            {day}
                        </label>
                    ))}
                </div>
            </div>
            {/* <label>Start Date:</label>
            <input
                type='date'
                className='border border-gray-300 rounded px-1'
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
                required
            />
            <label>Start Date:</label>
            <input
                type='date'
                className='border border-gray-300 rounded px-1'
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
                required
            /> */}
            <button
                type="submit"
                className='rounded-md py-2 px-4 hover:scale-105 transition centexBackgroundColour text-white'
            >
                Submit Reservation
            </button>
        </form>
    );
}

export default AddReservation;
