import React, { useState } from 'react';
import logo from "../Centex.png";
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from '../FireBase/firebase.config';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

function Authenticate() {
    const navigate = useNavigate();

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false)
    const [isRegisterPage, setIsRegisterPage] = useState(false)

    const handleLogin = async (e) => {
        e.preventDefault();
        setLoading(true);
        await signInWithEmailAndPassword(auth, email, password)
            .then((user) => {
                setLoading(false);
                navigate("/");
            })
            .catch((error) => {
                // Handle error
                toast.error("Error signing in");
                setLoading(false);
            });
    };

    return (
        <section id='login'
            className='section flex flex-col items-center justify-center h-screen centexBackgroundColour'
        >
            <div className='bg-white px-5 py-5 rounded-lg shadow-md w-80'>
                <img src={logo}
                    className='mx-auto p-2 h-12'
                />
                <form className='flex flex-col gap-4' onSubmit={handleLogin}>
                    <label htmlFor="email" className='text-gray-600'>Email:</label>
                    <input
                        type="email"
                        id="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        className='border border-gray-300 rounded-md p-2'
                    />
                    <label htmlFor="password" className='text-gray-600'>Password:</label>
                    <input
                        type="password"
                        id="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        className='border border-gray-300 rounded-md p-2'
                    />
                    <button
                        type="submit"
                        disabled={loading}
                        className='rounded-md py-2 px-4 hover:scale-105 transition centexBackgroundColour text-white'
                    >
                        {loading ? 'Authenticating...' : (isRegisterPage ? "Register" : "Login")}
                    </button>
                </form>
            </div>
        </section>
    )
}

export default Authenticate;
